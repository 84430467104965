import React, { useRef, useState } from "react";
import CSMPacking from "../../API/CSMPacking";
import PackingList from "./PackingList";
import Daily from "./Daily";
import Detail from "./Detail";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import fileDownload from "js-file-download";
import moment from "moment";
import { handleSizeLBSRetouching } from "../../Utility";

export default function PackingTable() {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();

  const _packing = new CSMPacking();
  const [packingList, setPackingList] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState();
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: null,
  });
  const ref = useRef();

  const handleReadCode = (lotCode) => {
    const material = lotCode.split("/");

    if (material.length !== 8 || material[7].length != 10) return;

    const data = {
      vendor: material[0],
      grade: material[1],
      weight: material[2],
      lot: material[3],
      origin: material[4],
      pdc: material[5],
      type: material[6],
      retouchingDate: material[7],
    };

    const isExist = packingList.filter(
      (a) =>
        a.grade != data.grade ||
        handleSizeLBSRetouching(a.weight) !=
          handleSizeLBSRetouching(data.weight)
    );

    if (isExist.length > 0) {
      setToastInfo({
        message: "cannot mix with different grade or size",
        background: "danger",
      });
      setIsShowToast(true);
    } else {
      _packing
        .GetPacking({ vendor: data.vendor, pdc: data.pdc })
        .then((response) => {
          data.receivingUuid = response.data.uuid;
          data.fishName = response.data.fishName;
          data.fishScientificName = response.data.fishScientificName;

          setPackingList([...packingList, data]);
        })
        .catch(() => {
          setToastInfo({
            message: "item not found",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }

    ref.current.value = "";
  };

  const handleExportDaily = () => {
    _packing
      .ExportDailyPacking(date)
      .then((response) => {
        const fileName = `packing(${date.start} - ${date.end}).csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Packing</h1>
        <div className="row">
          <div className="col-md-4 mt-3">
            <input
              ref={ref}
              type="text"
              className="form-control"
              onChange={(e) => handleReadCode(e.target.value)}
              placeholder="Scan Internal Lot Code"
              defaultValue={""}
            />
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-4 mt-3 d-flex">
            <input
              type="date"
              className="form-control me-1"
              value={date.start}
              onChange={(e) => setDate({ ...date, start: e.target.value })}
            />
            <input
              type="date"
              className="form-control me-1"
              value={date.end}
              onChange={(e) => setDate({ ...date, end: e.target.value })}
            />
            <button
              className="btn btn-primary me-1"
              onClick={handleExportDaily}
            >
              Download
            </button>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <PackingList
            packingList={packingList}
            setPackingList={setPackingList}
          />
          <Daily
            setSelectedDetail={setSelectedDetail}
            selectedDetail={selectedDetail}
          />
          <Detail
            selectedDetail={selectedDetail}
            setSelectedDetail={setSelectedDetail}
          />
        </div>
      </section>
    </main>
  );
}
