import React, { useEffect, useRef, useState } from "react";
import PackingModel from "../../API/CSMPacking";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { handleSizeLBSRetouching } from "../../Utility";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { Loading } from "../../Components";

export default function Detail({ selectedDetail, setSelectedDetail }) {
  const _packing = new PackingModel();
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const [packingList, setPackingList] = useState([]);
  const [packingDate, setPackingDate] = useState();
  const [uuid, setUuid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sackNo, setSackNo] = useState("");
  const ref = useRef();

  const getPacking = () => {
    setIsLoading(true);
    _packing
      .ReadPackingDetail({
        uuid: selectedDetail.uuid,
      })
      .then((response) => {
        setUuid(response.data.uuidheader);
        const databaru = response.data.data.map((item) => ({
          ...item,
          packingUuid: selectedDetail.uuid,
        }));

        setPackingList(databaru);
        setSackNo(response.data.sackNo);
        setIsLoading(false);
      });

    let dateStr = selectedDetail.packingDate;
    let dateArr = dateStr.split("-");
    dateArr.reverse();
    let newDateStr = dateArr.join("-");
    setPackingDate(newDateStr);
  };

  useEffect(() => {
    if (selectedDetail) {
      getPacking();
    }
  }, [selectedDetail]);

  const handleDelete = (data) => {
    const afterDeleted = packingList.filter((item) => item.uuid !== data.uuid);

    setPackingList([...afterDeleted]);
  };

  const totalWeight = () => {
    return packingList.reduce((sum, item) => sum + parseFloat(item.weight), 0);
  };

  const exportPdf = () => {
    var doc = new jsPDF("p", "mm", [150, 100]);

    QRCode.toDataURL(uuid, { margin: 1 }).then((lotCode) => {
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text("PDC No.", 5, 10);
      doc.text("Sack No.", 70, 10);

      doc.setFontSize(14);
      doc.text(getMostProperty("lot"), 30, 10);
      doc.text(getMostProperty("sackNo"), 90, 10);
      doc.setFontSize(11);
      doc.text(
        `FROZEN ${getMostProperty("fishName") || "YELLOWFIN TUNA"}  LOIN`,
        25,
        20
      );
      doc.text(
        `(${getMostProperty("fishScientificName") || "Thunus albacares"})`,
        35,
        24
      );

      doc.setFontSize(10);
      doc.text(`Grade`, 5, 35);
      doc.text(getMostProperty("grade"), 5, 38);

      doc.text(`Pieces`, 45, 35);
      doc.text(packingList.length.toString(), 45, 38);

      doc.text(`Net Weight`, 80, 35);
      doc.text(Number(totalWeight()).toFixed(2), 80, 38);

      doc.addImage(lotCode, "JPEG", 45, 42, 50, 50);

      doc.text(`Origin`, 5, 45);
      doc.text(getMostProperty("origin"), 5, 48);

      const pdc = getMostProperty("pdc");

      const [year, month, day] = pdc.split("-");

      const expiryYear = parseInt(year) + 2;

      doc.text(`Expiry Date`, 5, 56);
      doc.text(`${expiryYear}-${month}-${day}`, 5, 60);

      doc.text(`Production Date`, 5, 68);
      doc.text(getMostProperty("pdc"), 5, 71);

      doc.text(`Size`, 5, 79);
      doc.text(handleSizeLBSRetouching(getMostProperty("weight")), 5, 82);

      doc.text(`Processed By:`, 5, 100);
      doc.text(`CHOCKSAMUT MARINE CO.,`, 5, 105);
      doc.text(`57/40 Moo 4 Kokkham Muang, Samutsakorn 7400`, 5, 108);
      doc.text(`THAILAND`, 5, 111);

      doc.text(`Keep Frozen -18°C`, 5, 125);
      doc.text(`CONTAINS : Fish (Tuna)`, 5, 128);

      doc.autoPrint();
      var iframe = document.createElement("iframe");
      iframe.src = doc.output("datauristring");
      iframe.style.width = "0";
      iframe.style.height = "0";
      document.body.appendChild(iframe);
    });
  };

  const getMostProperty = (property) => {
    const propertyCounts = packingList.reduce((counts, item) => {
      counts[item[property]] = (counts[item[property]] || 0) + 1;
      return counts;
    }, {});

    const mostCommonProperty = Object.keys(propertyCounts).reduce((a, b) =>
      propertyCounts[a] > propertyCounts[b] ? a : b
    );

    return mostCommonProperty;
  };

  const handleUpdate = () => {
    _packing
      .UpdatePacking({
        uuid: selectedDetail.uuid,
        sackNo: sackNo,
        packingDate: packingDate,
        packingList: packingList,
      })
      .then((response) => {
        getPacking();
        setToastInfo({
          message: "packing successfully updated",
          background: "success",
        });
        setIsShowToast(true);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "packing failed updated",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const handleReadCode = (lotCode) => {
    const material = lotCode.split("/");

    if (material.length !== 8 || material[7].length != 10) return;

    const data = {
      vendor: material[0],
      grade: material[1],
      weight: material[2],
      lot: material[3],
      origin: material[4],
      pdc: material[5],
      type: material[6],
      retouchingDate: material[7],
    };

    const isExist = packingList.filter(
      (a) =>
        a.grade != data.grade ||
        handleSizeLBSRetouching(a.weight) !=
          handleSizeLBSRetouching(data.weight)
    );

    if (isExist.length > 0) {
      setToastInfo({
        message: "cannot mix with different grade or size",
        background: "danger",
      });
      setIsShowToast(true);
    } else {
      _packing
        .GetPacking({ vendor: data.vendor, pdc: data.pdc })
        .then((response) => {
          data.receivingUuid = response.data.uuid;
        });

      setPackingList([...packingList, data]);
    }

    ref.current.value = "";
  };

  if (!selectedDetail) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{sackNo}</h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDetail();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="col-xl-4">
              <input
                ref={ref}
                type="text"
                className="form-control"
                onChange={(e) => handleReadCode(e.target.value)}
                placeholder="scan internal lot code"
              />
            </div>
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control me-3"
                value={sackNo}
                onChange={(e) => setSackNo(e.target.value)}
              />
              <input
                type="date"
                className="form-control me-3"
                value={packingDate}
                onChange={(e) => setPackingDate(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate();
                }}
              >
                Update
              </button>
              <button
                className="btn btn-success ms-2"
                onClick={(e) => {
                  var textToCopy = uuid;

                  // Copy the text to the clipboard
                  navigator.clipboard
                    .writeText(textToCopy)
                    .then(() => {
                      // Inform the user
                      alert("Text has been copied: " + textToCopy);
                    })
                    .catch((err) => {
                      console.error("Could not copy text: ", err);
                    });
                }}
              >
                <i class="bi bi-c-square"></i>
              </button>
              <button
                className="btn btn-secondary mx-2"
                onClick={() => {
                  exportPdf();
                }}
              >
                <i className="bi bi-printer"></i>
              </button>
            </div>
          </div>
          <table className="table">
            {packingList && packingList.length > 0 && (
              <thead>
                <tr>
                  <td colSpan={6}>Total</td>
                  <td>{packingList.length} Pcs</td>
                  <td>{Number(totalWeight()).toFixed(2)} Kg</td>
                  <td></td>
                </tr>
              </thead>
            )}
            <thead>
              <tr>
                <th scope="col">Vendor</th>
                <th scope="col">Grade</th>
                <th scope="col">Lot</th>
                <th scope="col">PDC</th>
                <th scope="col">Origin</th>
                <th scope="col">Type</th>
                <th scope="col">Weight</th>
                <th scope="col">Size</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {packingList.length > 0 &&
                packingList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{item.vendor}</th>
                      <td>{item.grade}</td>
                      <td>{item.lot}</td>
                      <td>{item.pdc}</td>
                      <td>{item.origin}</td>
                      <td>{item.type}</td>
                      <td>{item.weight}</td>
                      <td>
                        {handleSizeLBSRetouching(
                          parseFloat(item.weight).toFixed(2)
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-danger py-0 px-1"
                          onClick={() => handleDelete(item)}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
